.time-wrapper > p {
  max-width: 960px;
  margin: 56px auto;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.time-wrapper > p strong {
  font-weight: 700;
}
.time-button-group {
  display: flex;
  justify-content: center;
}
.time-button-group a:first-of-type {
  margin-right: 16px;
}
.time-button-group a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 320px;
  height: 56px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  border-radius: 50px;
  border: 1px solid #0c0c4f;
  background: transparent;
}
.time-button-group a svg {
  margin-right: 12px;
}
.time-button-group a svg path {
  fill: currentColor;
}
.time-button-group a:hover {
  color: #fff;
  background: linear-gradient(90deg, #A15882 0%, #3928A0 53%, #171870 100%);
}

@media screen and (max-width: 1024px) {
  .time-wrapper {
    margin-top: -10px;
  }
  .time-button-group {
    flex-direction: column;
    align-items: center;
  }
  .time-button-group a:first-of-type {
    margin-right: 0;
    margin-bottom: 16px;
  }
  .time-wrapper > p {
    max-width: 380px;
    font-size: 18px;
    margin: 24px auto;
  }
}

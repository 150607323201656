.time-line {
  width: 934px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.time-line-mobile {
  display: none;
}
.time-line-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  flex: 1;
}
.time-line-item div.line {
  position: absolute;
  border-top: 1px dashed #0c0c4f;
  width: 90%;
  left: 50%;
  top: calc(50% - 11px);
  margin-left: 5%;
  margin-right: 5%;
}

.time-line-item h6 {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px; /* 130% */
}
.time-line-item p {
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.time-line-item div.time-line-item-round {
  margin: 12px 0;
  border: 1px solid;
  border-color: #0c0c4f;
  border-radius: 100%;
  width: 14px;
  height: 14px;
  box-shadow: 0px 0px 20px 0px #fff;
}
.time-line-item div.time-line-item-round span {
  display: inline-block;
  width: 8px;
  height: 8px;
  position: relative;
  top: -3.5px;
  left: 2px;
  background-color: #0c0c4f;
  border-radius: 100%;
}
.time-line-item.active h6, .time-line-item.active p {
  color: #F30B61;
}
.time-line-item.active div.time-line-item-round {
  border-color: #F30B61;
  box-shadow: 0px 0px 20px 0px #F30B61;
}

.time-line-item.active div.time-line-item-round span {
  background-color: #F30B61;
}

@media screen and (max-width: 1024px) {
  .time-line {
    display: none;
  }
  .time-line-mobile {
    width: 267px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .time-line-item {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .time-line-item + .time-line-item {
    margin-top: 25px;
  }
  .time-line-item div.time-line-item-round {
    position: relative;
    margin: 4px 30px 0 0;
  }
  .time-line-item div.time-line-item-round span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .time-line-item h6,
  .time-line-item p {
    text-align: left;
  }
  .time-line-item h6 {
    font-size: 21.38px;
    line-height: 27.79px;
  }
  .time-line-item p {
    margin-top: 2px;
    font-size: 17.1px;
    line-height: 25.65px;
  }
  .time-line-item-round::before {
    content: '';
    height: 55px;
    border-left: 1px dashed #0c0c4f;
    position: absolute;
    margin-left: 6px;
    margin-top: 20px;
  }
  .time-line-item-round.time-line-item-round-last:before {
    display: none;
  }
}

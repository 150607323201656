.header {
  display: flex;
  padding: 24px 36px;
  position: sticky;
  top: 0;
  z-index: 99;
  align-items: center;
  background-color: rgb(255 255 255 / 85%);
}
.header > a, .header > div {
  position: relative;
  z-index: 101;
}
.header-logo, .header-logo img {
  height: 36px;
}
.header-menu {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.header-menu a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  display: inline-block;
  margin-right: 32px;
  transition: 0.3s all;
}
.header-menu a:last-of-type {
  margin-right: 0;
}
/* .header a.registration-button {
  display: flex;
  align-items: center;
  width: 155px;
  height: 46px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  border: 1px;
  color: #FFF;
  background: #020214;
  background-clip: padding-box;
  border: solid 1px transparent;
  border-radius: 50px;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  transition: 0.3s all;
  margin-right: 12px;
}

.header a.registration-button::before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  z-index: -1;
  margin: -1px;
  border-radius: inherit;
  background: linear-gradient(to bottom, #EC796B, #D672EF);
} */

.header a.registration-button {
  width: 200px;
  height: 46px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  border-radius: 50px;
  background: linear-gradient(90deg, #A15882 0%, #3928A0 53%, #171870 100%);
  text-align: center;
  color: #fff;
  font-size: 16px;
  justify-content: center;
  font-weight: 700;
  transition: 0.3s all;
}
.header a.registration-button svg {
  margin-left: 8px;
}
.header a.registration-button:hover {
  opacity: 0.8;
}

.header-right-buttons {
  display: flex;
  align-items: center;
  width: 365px;
  justify-content: right;
}

.header-right-buttons a {
  margin-right: 12px;
}
.header-right-buttons a:last-of-type {
  margin: 0;
}

@media screen and (max-width: 1024px) {
  .header {
    padding: 43px 0 16px;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: static;
  }
  .header .mh {
    display: none;
  }
  .header-logo, .header-logo img {
    height: 30px;
  }
  .header-right-buttons {
    padding: 0 24px;
    justify-content: center;
    /* width: 100%; */
    margin: 32px 0 0;
    box-sizing: border-box;
  }
  .header-right-buttons a, .header-right-buttons img {
    display: inline-block;
    height: 40px;
    margin-right: 0 !important;
  }
  /* .header a.registration-button, .header a.go-team-button {
    height: 40px;
    font-size: 14px;
    line-height: 28px;
    width: 125px;
  } */
  .header a.registration-button {
    margin: 0;
  }
}

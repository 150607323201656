.rewards {
  width: 100%;
  background: transparent url("./rewards-bg.png") top center no-repeat;
  margin-top: 50px;
  padding-bottom: 40px;
}
.rewards > p {
  font-size: 24px;
  line-height: 26px;
  text-align: center;
  padding-top: 56px;
}
.rewards > h1 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  text-align: center;
  margin-top: 4px;
  margin-bottom: 62px;
}

.rewards > div {
  max-width: 1240px;
  margin: 0 auto;
}
.rewards-award-wrapper {
  display: grid;
  column-gap: 26px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
/* .rewards-award-item {
  border-radius: 32px;
  border: 1px solid rgba(215, 150, 255, 0.10);
  background: linear-gradient(180deg, rgba(215, 150, 255, 0.04) 0%, rgba(215, 150, 255, 0.00) 100%);
  backdrop-filter: blur(0px);
  padding: 16px;
} */
.rewards-award-item-headbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border-radius: 26px;
  border: 1px solid #0c0c4f;
  background: linear-gradient(180.6deg, rgba(161, 88, 130, 0.1) 0.55%, rgba(217, 217, 217, 0) 99.52%);
  padding: 18px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
}
.rewards-award-item-headbox > p:first-of-type {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}
.rewards-award-item-headbox > p:first-of-type::before {
  content: "✦ "
}
.rewards-award-item-headbox > p:first-of-type::after {
  content: " ✦"
}
/* .rewards-award-item > div:last-of-type { */
  /* border-bottom: 0 !important; */
  /* padding-bottom: 0 !important; */
/* } */
.rewards-award-item-award {
  padding: 24px 0;
  border-bottom: 1px solid rgba(215, 150, 255, 0.1);
}
.rewards-award-item-award p:first-of-type {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.rewards-award-item-award p:last-of-type {
  margin-top: 8px;
  color: #D796FF;
  font-size: 21px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  justify-content: space-between;
}
.rewards-award-item-award p:last-of-type span {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  opacity: 0.4;
}
.rewards > div.rewards-event-detail {
  margin: 26px auto 36px;
  border-radius: 32px;
  border: 1px solid #0c0c4f;
  padding: 16px;
  display: flex;
}
.rewards > div.rewards-event-detail h2 {
  width: 123px;
  height: 123px;
  flex-shrink: 0;
  border-radius: 26px;
  border: 1px solid #0c0c4f;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;
}
.rewards > div.rewards-event-detail ul {
  list-style: none outside disc;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-left: 48px;
}
.rewards > div.rewards-event-detail ul li {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px; /* 137.5% */
}
.rewards > div.rewards-event-detail ul li a {
  color: #3928A0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-decoration-line: underline;
  transition: 300ms all;
}
.rewards > div.rewards-event-detail ul li a:hover, .rewards-submission-deadline div a:hover, .interested a:hover {
  opacity: 0.8;
}
.rewards-submission-deadline {
  margin-top: 36px !important;
  font-size: 16px;
  line-height: 24px;
}
.rewards-submission-deadline div {
  text-align: center;
}
.rewards-submission-deadline div a {
  color: #3928A0;
  font-weight: 700;
  text-decoration: underline;
  transition: 300ms all;
}
.rewards-submission-deadline div a svg {
  margin-left: 4px;
}
.rewards-submission-deadline div a svg path {
  fill: currentColor;
}

@media screen and (max-width: 1024px) {
  .rewards {
    margin-top: 36px;
    background-size: 864px;
  }

  .rewards > p {
    padding-top: 22.55px;
    font-size: 12.72px;
    line-height: 13.78px;
  }

  .rewards > h1 {
    margin-bottom: 36.55px;
    font-size: 31.81px;
    line-height: 40.29px;
  }

  .rewards-award-wrapper {
    display: block;
    padding: 0 24px;
  }

  .rewards-award-item {
    height: 108px;
  }

  .rewards-award-item + .rewards-award-item {
    margin-top: 16px;
  }

  .rewards-award-item-headbox {
    align-items: center;
  }

  .rewards-award-item-headbox > p:last-of-type {
    max-width: 200px;
  }

  .rewards-award-item:last-child .rewards-award-item-headbox {
    background: linear-gradient(180.6deg, rgba(161, 88, 130, 0.1) 0.55%, rgba(217, 217, 217, 0) 99.52%) !important;
  }

  .rewards-award-item:not(:last-child) .rewards-award-item-headbox > p:last-of-type {
    font-size: 24px;
    line-height: 26px;
  }

  .rewards > div.rewards-event-detail {
    display: block;
    margin: 0 24px 24px;
  }

  .rewards > div.rewards-event-detail ul {
    padding: 16px;
  }

  .rewards > div.rewards-event-detail ul li {
    margin-bottom: 12px;
  }

  .rewards > div.rewards-event-detail ul li:last-of-type {
    margin-bottom: 0;
  }

  .rewards > div.rewards-event-detail ul {
    padding-bottom: 0;
  }

  .rewards > div.rewards-submission-deadline {
    max-width: 380px;
    margin: 16px auto 0 !important;
  }
}

.partner {
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0;
}
.partner h1 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
  padding-bottom: 36px;
  text-align: center;
}

.partner-content > div {
  padding: 36px 0;
  display: flex;
  align-items: flex-start;
}

.partner-content > div + div {
  border-top: 1px solid rgba(12, 12, 79, 0.2);
}

.partner-content > div > h6 {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  width: 243px;
  margin-right: 87px;
}
.partner-content > div > h6 span {
  display: none;
}
.partner-content > div > div {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 36px;
}

.partner-content > div > div > img {
  margin-bottom: 36px;
}

@media screen and (max-width: 1024px) {
  .partner {
    padding-bottom: 48px;
  }

  .partner h1 {
    padding-bottom: 16px;
    font-size: 32px;
    line-height: 38px;
  }

  .partner-content > div {
    flex-direction: column;
    padding: 8px 0;
    border-top-style: none !important;
  }

  .partner-content > div > h6 {
    width: 100%;
    margin-right: 0;
    text-align: center;
    margin-bottom: 12px;
    font-size: 16px;
    font-weight: 400;
  }
  .partner-content > div > h6 > span {
    display: inline;
  }

  .partner-content > div > div {
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    padding: 0 24px;
  }
  .partner-content > div > div > div {
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid rgba(12, 12, 79, .1)
  }

  .partner-content > div > div > div img {
    width: 80%;
    max-height: 100%;
  }
}

.judging {
  max-width: 1280px;
  margin: 0 auto;
  padding: 40px 0;
}
.judging > h1 {
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px;
}
.judging > p {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 24px;
  margin-bottom: 56px;
}
.judging > h1 , .judging > p {
  text-align: center;
}
.judging-columns {
  display: grid;
  column-gap: 70px;
  grid-template-columns: 1fr 1fr;
}
.JudgingRule-heading {
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
.JudgingRule-description {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  margin-top: 12px;
  margin-bottom: 36px;
}
.JudgingRule-rules li span {
  font-weight: bolder;
}
.judging-centent-mobile {
  display: none;
}
.JudgingRule-rules li {
  list-style: auto;
  margin-left: 24px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.JudgingRule-rules li + li {
  margin-top: 16px;
}
.judging-columns-sub {
  margin-top: 24px;
}
.judging-centent-textbox {
  border-radius: 20px;
  border: 1px solid rgba(12, 12, 79, 0.1);
  padding: 16px;
}
.judging-centent-textbox > p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  opacity: 0.4;
}

@media screen and (max-width: 1024px) {
  .judging > h1, .introduction-text h1 {
    font-size: 40px;
    line-height: 38px;
  }
  .judging {
    margin: 16px auto;
    padding: 0 24px;
  }
  .judging > p {
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
    margin-bottom: 32px;
  }
  .judging-centent {
    display: none;
  }
  .judging-centent-mobile {
    display: block;
  }
  .judging-centent-mobile > div + div {
    margin-top: 36px;
  }
  .JudgingRule-heading {
    font-size: 24px;
  }
  .JudgingRule-description {
    margin: 4px 0 16px;
    font-size: 16px;
  }
  .JudgingRule-rules li + li {
    margin-top: 8px;
  }
  .judging-centent-textbox {
    margin-top: 16px;
  }
  .judging-centent-textbox > p {
    font-size: 12px;
  }
}

.onboard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1240px;
  margin: 0 auto;
}
.onboard-text h1 {
  font-size: 80px;
  font-style: normal;
  font-weight: 700;
  max-width: 470px;
}
.onboard-text p {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 423px;
  height: 52px;
  margin-top: 36px;
  font-size: 24px;
  font-weight: bold;
  background: transparent url("./duration-bg-back.png") left center no-repeat;
}
.onboard-text p::before {
  content: "Time";
  padding-left: 49px;
  color: #fff;
  text-transform: uppercase;
}
.onboard-text p::after {
  content: "\0020";
  position: absolute;
  top: 50%;
  left: 19px;
  width: 25px;
  height: 25px;
  transform: translateY(-50%);
  background: transparent url("./clock.svg") 50% 50% no-repeat;
}
.onboard-text p span {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 298px;
  height: 52px;
  background: transparent url("./duration-bg-fore.png") left center no-repeat;
}
.onboard-bg {
  width: 619px;
  height: 606px;
}

@media screen and (max-width: 1024px) {
  .onboard {
    flex-direction: column;
    padding: 16px 24px 0;
    overflow-x: hidden;
  }
  .onboard-text h1 {
    font-size: 60px;
    line-height: 56px;
    text-align: center;
  }
  .onboard-text p {
    margin-top: 12px;
    transform: scale(0.615);
  }
  .onboard-bg {
    max-width: 100%;
    height: auto;
    margin-top: -30px;
  }
}


.introduction {
  padding-top: 80px;
  padding-bottom: 98px;
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
}
.introduction-text {
  max-width: 683px;
  margin-right: 114px;
}
.introduction-text p {
  margin-top: 24px;
  color: #FFF;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.introduction-text p span, .introduction-text p strong {
  font-weight: 700;
}
.introduction-text p strong {
  text-decoration: underline;
}
.introduction-text h1 {
  color: #D796FF;
  text-shadow: 0px 0px 34px rgba(215, 150, 255, 0.60);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 76px; /* 126.667% */
}
.introduction img {
  width: 100%;
}
.interested {
  text-align: center;
  margin-top: 75px;
  margin-bottom: 84px;
}

.interested h1 {
  color: #D796FF;
  text-align: center;
  text-shadow: 0px 0px 34px rgba(215, 150, 255, 0.60);
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 66px;
}
.interested div {
  height: 24px;
}
.interested p {
  max-width: 760px;
  margin: 0 auto;
  color: #FFF;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  line-height: 32px;
}

.interested p strong {
  font-weight: 700;
}
.interested a {
  display: inline-block;
  border-radius: 50px;
  border: 1px solid #D796FF;
  color: #D796FF;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  width: 318px;
  height: 56px;
  flex-shrink: 0;
  line-height: 56px;
  margin-top: 36px;
  transition: all 300ms;
}

.introduction {
  padding: 0 24px;
  flex-direction: column-reverse;
  margin-bottom: 40px;
}

.introduction div:last-of-type {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.introduction img {
  max-width: 50%;
}

.introduction-text p {
  font-size: 16px;
}
.introduction-text {
  margin-right: 0;
  max-width: 100%;
}

.footer {
  background: linear-gradient(253.04deg, #E8F1FE 1.44%, #F7E7EE 105.75%);
  padding: 64px 0;
}
.footer > div {
  display: flex;
  max-width: 1280px;
  margin: 0 auto;
  align-items: center;
}
.footer-contact {
  display: flex;
  flex: 1;
}
.footer-contact h1 {
  font-size: 64px;
  font-style: normal;
  font-weight: 900;
  line-height: 72px;
  margin-bottom: 16px;
}
.footer-contact p {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 42px;
}
.footer-contact > div:last-of-type a {
  width: 132px;
  height: 132px;
  border-radius: 132px;
  border: 1px solid #0c0c4f;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 64px;
  transition: all 300ms;
  overflow: hidden;
}
.footer-contact > div:last-of-type a svg {
  transition: all 300ms;
}
.footer-contact > div:last-of-type a svg path {
  fill: currentColor;
}
.right-arrow {
  transform: translateX(20px);
}
.footer-contact > div:last-of-type a:hover .right-arrow {
  transform: translateX(200px);
}
.left-arrow {
  transform: translateX(-200px);
}
.footer-contact > div:last-of-type a:hover .left-arrow {
  transform: translateX(-20px);
}
.got {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px; /* 200% */
  justify-content: center;
  transition: all 300ms;
}
.got:hover svg {
  margin-left: 12px;
}
.got svg {
  margin-left: 8px;
  transition: all 300ms;
}
.got svg path {
  fill: currentColor;
}
.open-email {
  display: none;
}

@media screen and (max-width: 1024px) {
  .interested {
    margin-bottom: 50px;
    margin-top: 50px;
  }

  .interested h1 {
    font-size: 40px;
    line-height: 46px;
  }

  .interested p {
    font-size: 18px;
  }

  .footer {
    height: 195px;
    padding: 0;
  }

  .footer-contact {
    display: none;
  }
  .footer > div {
    height: 100%;
    justify-content: center;
  }
  .footer > div > div {
    text-align: center;
  }
  .got {
    line-height: 24px;
  }
  .footer > div > div p:first-of-type {
    margin: 16px 0;
  }
  .open-email {
    display: flex;
  }
}
